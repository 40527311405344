const Stock = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Stock.vue");
const StockInventories = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/StockInventory/StockInventories.vue"
  );

const CreateStockInventory = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/StockInventory/CreateStockInventory.vue"
  );

const EditStockInventory = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/StockInventory/EditStockInventory.vue"
  );

const StockInventory = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/StockInventory/StockInventory.vue"
  );

const MachineInventories = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/MachineInventory/MachineInventories.vue"
  );

const CreateMachineInventory = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/MachineInventory/CreateMachineInventory.vue"
  );

const EditMachineInventory = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/MachineInventory/EditMachineInventory.vue"
  );

const MachineInventory = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/MachineInventory/MachineInventory.vue"
  );

const GeneralStock = () =>
  import(/* webpackChunkName: "common" */ "@/pages/GeneralStock.vue");

const stockRoutes = [
  {
    path: "stock",
    name: "stock",
    component: Stock,
    meta: { requiresAuth: true },
  },
  {
    path: "create/stock/inventory",
    name: "createStockInventory",
    component: CreateStockInventory,
    meta: { requiresAuth: true },
  },
  {
    path: "stock/inventories",
    name: "stockInventories",
    component: StockInventories,
    meta: { requiresAuth: true },
  },
  {
    path: "stock/inventory/:id",
    name: "stockInventory",
    component: StockInventory,
    meta: { requiresAuth: true },
  },
  {
    path: "edit/stock/inventory/:id",
    name: "editStockInventory",
    component: EditStockInventory,
    meta: { requiresAuth: true },
  },
  {
    path: "create/machine/inventory",
    name: "createMachineInventory",
    component: CreateMachineInventory,
    meta: { requiresAuth: true },
  },
  {
    path: "machines/inventories",
    name: "machineInventories",
    component: MachineInventories,
    meta: { requiresAuth: true },
  },
  {
    path: "machine/inventory/:id",
    name: "machineInventory",
    component: MachineInventory,
    meta: { requiresAuth: true },
  },
  {
    path: "edit/machine/inventory/:id",
    name: "editMachineInventory",
    component: EditMachineInventory,
    meta: { requiresAuth: true },
  },
  {
    path: "stock/report/general",
    name: "generalStock",
    component: GeneralStock,
    meta: { requiresAuth: true },
  },
];

export { stockRoutes };
