/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";

import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import store from "./store/index";

import i18n from "./i18n";
import BlackDashboard from "./plugins/blackDashboard";
import "./registerServiceWorker";

import "leaflet/dist/leaflet.css";
import VueApexCharts from "vue-apexcharts";
import Paginate from "vuejs-paginate";

import api from "@/services/api";
import {
  uniAngleLeft,
  uniCalendarAlt,
  uniCopy,
  uniEdit,
  uniFilter,
  uniMinus,
  uniPlus,
  uniShoppingBasket,
  uniSync,
  uniTrashAlt,
  uniSort,
  uniSortAmountDown,
  uniSortAmountUp,
  uniSearch,
  uniListUl,
  uniAngleDown,
  uniAngleUp,
} from "vue-unicons/dist/icons";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import { HandleErrors } from "./errors/handleErrors";
Unicon.add([
  uniPlus,
  uniEdit,
  uniTrashAlt,
  uniShoppingBasket,
  uniFilter,
  uniCalendarAlt,
  uniSync,
  uniMinus,
  uniAngleLeft,
  uniCopy,
  uniSort,
  uniSortAmountDown,
  uniSortAmountUp,
  uniSearch,
  uniListUl,
  uniAngleUp,
  uniAngleDown,
]);
Vue.use(VueApexCharts);
Vue.use(BlackDashboard);
Vue.component("paginate", Paginate);
Vue.component("apexchart", VueApexCharts);
Vue.prototype.$handleErrors = new HandleErrors();
Vue.prototype.$http = api;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
