export class HandleErrors {
  message(error) {
    let errorMessage =
      "Entre em contato com o nosso suporte. suporte@snackly.com";
    if (error?.response) {
      const { message } = error.response.data;
      errorMessage = message;
      console.log("[RESPONSE_ERROR] ", error.response.data);
    } else if (error?.request) {
      console.log("[REQUEST_ERROR] ", error.request);
    } else {
      console.log("[ERROR] ", error.message);
    }
    return errorMessage;
  }
}
