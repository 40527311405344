<template>
  <div class="wrapper">
    <side-bar title="Snackly">
      <template slot="links">
        <sidebar-link
          :v-show="hasPermissions('home')"
          to="/home"
          :name="$t('sidebar.home')"
          icon="tim-icons icon-single-02"
        />
        <sidebar-link
          v-show="hasPermissions('dashboard')"
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <!-- <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-map-big"/> -->
        <sidebar-link
          v-show="hasPermissions('purchases')"
          to="/purchases"
          :name="$t('sidebar.purchases')"
          icon="tim-icons icon-bag-16"
        />
        <sidebar-link
          v-show="hasPermissions('machines')"
          to="/machines"
          :name="$t('sidebar.machines')"
          icon="tim-icons icon-components"
        />
        <sidebar-link
          v-show="hasPermissions('stock')"
          to="/stock"
          :name="$t('sidebar.stock')"
          icon="tim-icons icon-basket-simple"
        />

        <sidebar-link
          v-show="hasPermissions('products')"
          to="/products"
          :name="$t('sidebar.products')"
          icon="tim-icons icon-basket-simple"
        />
        <sidebar-link
          v-show="hasPermissions('moviments')"
          to="/moviments"
          :name="$t('sidebar.moviments')"
          icon="tim-icons icon-basket-simple"
        />
        <sidebar-link
          v-show="hasPermissions('generalStock')"
          to="/stock/report/general"
          :name="$t('sidebar.generalStock')"
          icon="tim-icons icon-paper"
        />
        <sidebar-link
          v-show="hasPermissions('minimumStockReport')"
          to="/report/minimum/stock"
          :name="$t('sidebar.minimumStockReport')"
          icon="tim-icons icon-paper"
        />
        <sidebar-link
          v-show="hasPermissions('salesReport')"
          to="/sales/report"
          :name="$t('sidebar.salesReport')"
          icon="tim-icons icon-paper"
        />
        <li
          class="nav-item"
          v-show="
            hasPermissions('createStockInventory') ||
            hasPermissions('stockInventories') ||
            hasPermissions('createMachineInventory') ||
            hasPermissions('machineInventories')
          "
        >
          <a
            class="nav-link"
            id="inventories"
            data-toggle="collapse"
            data-target="#inventories"
            aria-expanded="true"
            aria-controls="#inventories"
          >
            <i class="tim-icons icon-paper"></i>
            <p>Inventários</p>
          </a>
          <div id="inventories" class="collapse">
            <sidebar-link
              :sublink="true"
              v-show="hasPermissions('createStockInventory')"
              to="/create/stock/inventory"
              :name="$t('sidebar.createStockInventory')"
              icon="tim-icons icon-paper"
            />
            <sidebar-link
              :sublink="true"
              v-show="hasPermissions('stockInventories')"
              to="/stock/inventories"
              :name="$t('sidebar.stockInventories')"
              icon="tim-icons icon-paper"
            />
            <sidebar-link
              :sublink="true"
              v-show="hasPermissions('createMachineInventory')"
              to="/create/machine/inventory"
              :name="$t('sidebar.createMachineInventory')"
              icon="tim-icons icon-paper"
            />
            <sidebar-link
              :sublink="true"
              v-show="hasPermissions('machineInventories')"
              to="/machines/inventories"
              :name="$t('sidebar.machineInventories')"
              icon="tim-icons icon-paper"
            />
          </div>
        </li>

        <!-- <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/> -->
        <!-- <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import { mapGetters } from "vuex/dist/vuex.common.js";
import SidebarLink from "../../components/SidebarPlugin/SidebarLink.vue";
import { PERMISSIONS } from "../../services/permissions";
import DashboardContent from "./Content.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./MobileMenu";
import TopNavbar from "./TopNavbar.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    SidebarLink,
  },
  methods: {
    ...mapGetters({
      isAdmin: "manager/isManagerAdmin",
      getManager: "manager/getManager",
    }),
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    hasPermissions(routerName) {
      const manager = this.getManager();
      const { user_type } = manager;
      return this.isAdmin()
        ? this.isAdmin()
        : PERMISSIONS[user_type].includes(routerName);
    },
  },
};
</script>
