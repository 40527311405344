const SalesReport = () =>
  import(/* webpackChunkName: "common" */ "@/pages/SalesReport.vue");

const StockMetrics = () =>
  import(/* webpackChunkName: "common" */ "@/pages/StockMetrics.vue");

const MinimumStockReport = () =>
  import(/* webpackChunkName: "common" */ "@/pages/MinimumStockReport.vue");

const reportRoutes = [
  {
    path: "sales/report",
    name: "salesReport",
    component: SalesReport,
    meta: { requiresAuth: true },
  },
  {
    path: "report/stock/metrics",
    name: "stockMetrics",
    component: StockMetrics,
    meta: { requiresAuth: true },
  },
  {
    path: "report/minimum/stock",
    name: "minimumStockReport",
    component: MinimumStockReport,
    meta: { requiresAuth: true },
  },
];

export { reportRoutes };
